import React, { useState, useEffect } from "react";
import "./nav.scss";
import navLogo from "../../Assets/images/(horizontal)-zupaloop-logo.png";
import download from "../../Assets/images/image 1313 (1).png";
import "../../scroll.js";
function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const handleLinkClick = () => {
    setShowMenu(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 450) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <nav className="navbar">
      <div className={scrolled ? 'logo scrolled' : 'navbar-logo'}>
        <a href="#">
          <img src={navLogo} width="180px"/>
        </a>
      </div>
      <ul className={`navbar-list ${showMenu ? "show" : ""}`}>
        
        {/* <li className="navbar-item">
          <a href="#contact" onClick={handleLinkClick}>Contact</a>
        </li> */}
        <li className="navbar-item">
          <a
            href="https://apps.apple.com/in/app/zupaloop/id1625427089?mt=12"
            onClick={handleLinkClick}
          ><img className="downloadimage" src={download}/>
            {/* <button>Download Now</button> */}
          </a>
        
        </li>
      </ul>
      <button className="navbar-toggle" onClick={() => setShowMenu(!showMenu)}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </nav>
  );
}

// var wrapper = document.querySelector('navToggler');  
// console.log(wrapper);
// document.addEventListener('click', function(e) {
//    if ( !wrapper.contains(e.target) ) {
//        // Do something when user clicked outside of wrapper element
//    }
// })



export default Navbar;
