import React from "react";
import './About.scss';
import curve1 from "../../Assets/images/Ellipse 3.png";
import curve2 from "../../Assets/images/Ellipse 4.png";

function About() {
  return (
    <div className="about" id="about">
      <div className="aboutheading-container">
      <h1 className="aboutheading"><span>About</span></h1>
        {/* <img src={curve2} alt="Curve" className="curve1" />
        <img src={curve1} alt="Curve" className="curve2" /> */}
      </div>
      <p className="aboutpara">
        Zupaloop is an ideal screen capture tool with access to device camera for
        capturing real time actions, with an iCloud sync letting access to
        videos over multiple devices and keep them secure. It is a perfect tool
        for individuals and teams looking to create curated videos or someone
        who needs to share their knowledge and skills.
      </p>
    </div>
  );
}

export default About;
