// App.js

import Navbar from "./components/Navbar/Navbar.js";

import About from "./components/About/About";
import HomeBanner from "./components/HomeBanner/HomeBanner";
// import Contact from "./components/Contact/Contact";
import Feature from "./components/Features/Feature";
// import Download from "./components/Download/Download.js";
import Footer from "./components/Footer/Footer";
import Users from "./components/Users/Users";
import './App.css';
function App() {
  return (
    <><Navbar /><HomeBanner />
    <div className="main">
      <About />
      <Feature />
      <Users />
      <Footer />
      {/* <Download /> */}
      {/* <Contact /> */}
    </div></>
  
  );
}

export default App;
