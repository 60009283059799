import React from "react";
import "./Features.css";
import Lap1 from "../../Assets/images/Group 100.png";
import Lap2 from "../../Assets/images/Group 101.png";
import Lap3 from "../../Assets/images/Group 105.png";
import Lap4 from "../../Assets/images/Group 99.png";

function Feature() {
  return (
    <div className="feature">
      <h3 className="featureheading"><span>Features</span></h3>

      <div className="cards">
        <div className="card">
          <div className="card__image">
            <img className="Laptops" src={Lap1} alt="Card 1" />
          </div>
          <div className="card__content">
            <p>
              Quickly capture and organize information from meetings, tutorials,
              and other events with just one click and save time. 
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card__image">
            <img className="Laptops" src={Lap2} alt="Card 2" />
          </div>
          <div className="card__content">
            <p>
              Annotating tools add context to your videos while recording your
              dynamic content, making it all quick and simpler.
            </p>
          </div>
        </div>
        <div className="card2">
          <div className="card__image">
            <img className="Laptops" src={Lap3} alt="Card 3" />
          </div>
          <div className="card__content">
            <p>
              User-friendly interface to review your recording with quick
              editing tools for trimming, cropping your content.
            </p>
          </div>
        </div>
        <div className="card2">
          <div className="card__image">
            <img className="Laptops" src={Lap4} alt="Card 4" />
          </div>
          <div className="card__content">
            <p>
              Gallery of your captured and shared videos synced from iCloud,
              play and share them from multiple devices.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature;
