import React from "react";
import "./Footer.scss";
import logo from "../../Assets/images/zup.png";
import instagramIcon from "../../Assets/images/instagram.png";
import linkedInIcon from "../../Assets/images/linkedin.png";
import location from "../../Assets/images/tabler_map-pin.png";
import mail from "../../Assets/images/lucide_mail.png";
function Footer() {
  return (
    <div className="footer">
      <div className="footer-grid">
        <div className="footer-section1">
          <img src={logo} alt="Logo" className="footer-logo" />
          <h4>
            Find us{" "}
            <a href="https://www.google.com/maps/place/Sri+Kathyayani/@13.8453622,75.1401282,9z/data=!4m10!1m2!2m1!1sNo+3-59B,+Sri+Kathyayani+Temple+Road,+Ambalapadi,+Udupi+Karnataka-576103!3m6!1s0x3bbcbb73f2dd4363:0x231664ffdad754d2!8m2!3d13.337587!4d74.733202!15sCkhObyAzLTU5QiwgU3JpIEthdGh5YXlhbmkgVGVtcGxlIFJvYWQsIEFtYmFsYXBhZGksIFVkdXBpIEthcm5hdGFrYS01NzYxMDOSAQ9ob3VzaW5nX3NvY2lldHngAQA!16s%2Fg%2F11shn77r8f">
              <img src={location} width="18px" />
            </a>
          </h4>
          <p className="footer-address">
            Zupaloop Private Limited<br></br>
            No 3-59B, Sri Kathyayani Temple Road,<br></br> Ambalapadi,
            Udupi<br></br>
            Karnataka-576103
            <br />
          </p>
        </div>
        <div className="footer-section2">
          <div className="footer-section2-flex">
            <h4 className="footer-section-heading">Connect</h4>
           
            <div className="footer-social-icons">
            <a
                href="mailto:developer@codezyng.com"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={mail}
                  alt="Email"
                  className="mailicon"
                  width="18px"
                />
              </a>
              <a
                href="https://www.instagram.com/codezyng/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={instagramIcon}
                  alt="Instagram"
                  className="social-icon"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/72288003/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={linkedInIcon}
                  alt="LinkedIn"
                  className="social-icon"
                />
                </a>
               
            </div>
          </div>
        </div>
        <div className="footer-section3">
        <div className="footer-section2-flex">

          <h4 className="footer-section-heading">Policies</h4>

          <a
            href="https://privacy.zupaloop.com/"
          >
            <p className="footer-email">Privacy Policy</p>
          </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <p>
          Copyright&copy;2023{" "}
          <a class="codezyng" href="">
            Zupaloop Private Ltd
          </a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
