import React from "react";

import LapMobile from "../../Assets/images/Group 100.png";
import zup from "../../Assets/images/zup.png";
// import apple from "../../Assets/images/apple.png";
import cz from "../../Assets/images/Group 169.png";
import "./HomeBanner.css";
import Laptop from "../../Assets/images/Group 168.png";
import appstore from "../../Assets/images/image 1313 (1).png";

// import appstore from "../../Assets/images/Download_on_the_Mac_App_Store_Badge_US-UK_RGB_wht_092917.svg";

function HomeBanner() {
  return (
    <div className="zupaloop" id="zupaloop">
      <div className="pro">
        <div className="details">
          <img className="logo" src={zup} alt="Zupaloop Logo" />
          <p className="description">Screen-Action tool for Mac</p>
          <a
            // className="button"
            href="https://apps.apple.com/in/app/zupaloop/id1625427089?mt=12"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="appstore" src={appstore}/>
            {/* <img className="icon" src={apple} alt="Apple Icon" />
            <div className="text">
              <span className="text-small">Download on the</span>
              <br></br>
              <span className="text-large">Mac App Store</span>
            </div> */}
          </a>

          <div className="brand">
            <a href="https://codezyng.com">
              <img className="brand-logo" src={cz} alt="Code Zyng Logo" />
            </a>
          </div>
        </div>

        <div className="image-container">
          <img
            className="image-left"
            src={Laptop}
            alt="MacBook Pro 16 inch Right View Mockup"
          />

          <img
            className="image-mobile mobile-only"
            src={LapMobile}
            alt="Macbook Pro Mobile View"
          />
        </div>
      </div>
    </div>
  );
}
export default HomeBanner;
