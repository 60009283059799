import React from "react";
import "./Users.scss";
import contentCreators from "../../Assets/images/Group 127 (1).png";
import students from "../../Assets/images/Group 126 (1).png";
import developers from "../../Assets/images/Group 128 (1).png";

const CardUser = (props) => {
  return (
    <div className="cardUser">
      <img src={props.imageUrl} alt={props.title} />
      {/* <div class="button-container">
        <button type="button">{props.title}</button>
      </div> */}
      <div className="cuntent">
      <p className="feature1">{props.title}</p>
      <span className="discription">{props.discription}</span>
    </div>
    </div>
  );
};

const Users = () => {
  const cardsData = [
    {
      id: 1,
      title: "Content Creators",
      discription:
        "Our screen action tool is the perfect solution for crafting videos, tutorials, resolving doubts, and enhancing your virtual creativity. With the ability to capture every action and screen in real-time, Whether you're teaching a new skill, solving a tricky problem, or simply sharing your creativity, our tool enables you to create engaging and informative content.",
      imageUrl: contentCreators,
    },
    {
      id: 2,
      title: "Students",
      discription:
        "Zupaloop is a new way to take video notes, capture your screen in real-time with camera support to add actions to your video notes and find them anytime on multiple devices for your quick reference.",
      imageUrl: students,
    },
    {
      id: 3,
      title: "Developers",
      discription:
        "Use Zupaloop to capture your screens and make your tasks more productive and creative for presentations, highlighting key information, demo a software or design and also share them to your team or audience with iCloud support.",
      imageUrl: developers,
    },
  ];

  return (
    <div>
<h3 class="usersheading"><span>Zupaloop:</span> Tool for Everyone</h3>

      <div className="card-list">
        {cardsData.map((card) => (
          <CardUser
            key={card.id}
            title={card.title}
            discription={card.discription}
            imageUrl={card.imageUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default Users;
